import React, { Component } from 'react';
import { CarouselCaption, CarouselItem } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from '..//Assets/slide1.jpg';
import slide2 from '../Assets/slide2.jpg';
import slide3 from '../Assets/slide3.jpg';

class CarouselBox extends Component {
    render() {
        return (
            <div>
                <Carousel>
                    <CarouselItem>
                        <img 
                            className='d-block w-100'
                            src={ slide1 }
                            alt='Jeep Compass Blue'
                        />
                        <CarouselCaption>
                            <h3>Jeep Compass Blue</h3>
                            <p>Компактний кросовер, що випускається з 2006 року «позашляховим» підрозділом Chrysler</p>
                        </CarouselCaption>
                    </CarouselItem>

                    <CarouselItem>
                        <img 
                            className='d-block w-100'
                            src={ slide2 }
                            alt='Jeep Compass Red'
                        />
                        <CarouselCaption>
                            <h3>Jeep Compass Red</h3>
                            <p>Компактний кросовер, що випускається з 2006 року «позашляховим» підрозділом Chrysler</p>
                        </CarouselCaption>
                    </CarouselItem>

                    <CarouselItem>
                        <img 
                            className='d-block w-100'
                            src={ slide3 }
                            alt='Jeep Compass Black'
                        />
                        <CarouselCaption>
                            <h3>Jeep Compass Black</h3>
                            <p>Компактний кросовер, що випускається з 2006 року «позашляховим» підрозділом Chrysler</p>
                        </CarouselCaption>
                    </CarouselItem>
                </Carousel>
            </div>
        );
    }
}

export default CarouselBox;