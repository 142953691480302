import React, { Component } from 'react';
import { Button, Container, Form, FormControl, Nav, Navbar, NavbarBrand, NavbarCollapse, NavbarToggle } from 'react-bootstrap';
import logo from './logo192.png'
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from '../Pages/Home';
import About from '../Pages/About';
import Blog from '../Pages/Blog';
import Contacts from '../Pages/Contacts';


class Header extends Component {
    render() {
        return (
            <>
                <Navbar sticky='top' collapseOnSelect expand="md" bg="dark" variant='dark'>
                    <Container>
                        <NavbarBrand href='/'>
                            <img
                                src={logo}
                                height="30"
                                width="30"
                                className="d-inline-block align-top"
                                alt="logo"
                            />
                        </NavbarBrand>

                        <NavbarToggle aria-controls="responsive-navbar-nav" />
                        <NavbarCollapse id='responsive-navbar-nav'>
                            <Nav className='me-auto'>
                                <Nav.Link href='/'> Home </Nav.Link>
                                <Nav.Link href='/about'> About us </Nav.Link>
                                <Nav.Link href='/contacts'> Contacts </Nav.Link>
                                <Nav.Link href='/blog'> Blog </Nav.Link>
                            </Nav>
                            <Form className='d-flex'>
                                <FormControl
                                    type='text'
                                    placeholder='Search'
                                    className='mr-sm-2'
                                />
                                <Button variant="outline-info">Search</Button>
                            </Form>
                        </NavbarCollapse>
                    </Container>
                </Navbar>

              <Router>
                <Routes>
                    <Route exact path="/" element={<Home/>} />
                    <Route exact path="/about" element={<About/>} />
                    <Route exact path="/contacts" element={<Contacts/>} />
                    <Route exact path="/blog" element={<Blog/>} />
                </Routes>
              </Router>  

            </>
        );
    }
}

export default Header;