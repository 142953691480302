import React, { Component } from 'react';

class Blog extends Component {
    render() {
        return (
            <div>
                This is Blog!
            </div>
        );
    }
}

export default Blog;