import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header';

function App() {
  return (
  <div>
    <Header />
   </div>
  );
}

export default App;
